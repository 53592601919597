/**
 * @prettier
 */
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Theme } from 'talkable-ui-kit'

import { Variable } from './variable'

const NoMatches = styled.div`
  margin-left: 30px;
  color: #333;
  font-size: 14px;
  ${Theme.fontWeights.regular}
`

export const Variables = observer(
  class Variables extends React.Component {
    render() {
      const {
        infoSidebarStore: {
          data,
          toggleOpenByPath,
          showVariables,
          pathActiveMatchNode,
          gaTrackCopyVariable,
        },
      } = this.props

      return showVariables ? (
        _.map(data, (item, interpolationKey) => (
          <Variable
            key={interpolationKey}
            name={interpolationKey}
            {...item}
            toggleOpenByPath={toggleOpenByPath}
            pathActiveMatchNode={pathActiveMatchNode}
            gaTrackCopyVariable={gaTrackCopyVariable}
          />
        ))
      ) : (
        <NoMatches>No matching variables</NoMatches>
      )
    }
  },
)
