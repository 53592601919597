/**
 * @prettier
 */
import { observer } from 'mobx-react'
import styled, { css } from 'styled-components'
import { UICopyable, UIIcon } from 'talkable-ui-kit'

import { EMPTY_OBJECT, EMPTY_ARRAY, getClassVariableByPath } from '../info_sidebar_store'

const TriangleWarningStyleOverride = styled(UIIcon)`
  width: 12px;
  height: 12px;
  margin-left: 6px;
`

const WithDeprecatedIcon = ({ children, mark }) => {
  return (
    <div className="flex align-items-center">
      {children}
      {mark === 'deprecated' && (
        <div
          className="flex align-items-center"
          title="Variable is deprecated"
          data-toggle="tooltip"
          data-placement="top"
          data-container="body"
        >
          <TriangleWarningStyleOverride name="triangleWarning" />
        </div>
      )}
    </div>
  )
}

const View = styled.div`
  margin-bottom: 10px;
`

const HeadContainer = styled.div`
  ${props =>
    props.open
      ? css`
          position: sticky;
          top: -20px;
          z-index: 1;
          margin-right: 10px;
          background-color: #fff;
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.16);
        `
      : css`
          position: relative;
        `};
  display: flex;
  padding-right: 30px;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  ${props =>
    props.match &&
    css`
      background-color: #e5f0fb;
    `};

  ${props =>
    props.active &&
    css`
      background-color: yellow;
    `};

  &:hover {
    background-color: #e5f0fb;
  }
`

const NestedContainer = styled.div`
  margin-left: 25px;
`

const NestedKey = styled.div`
  margin-left: 25px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;

  ${props =>
    props.spaceBetween &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `};

  ${props =>
    props.match &&
    css`
      background-color: #e5f0fb;
    `};

  ${props =>
    props.active &&
    css`
      background-color: yellow;
    `};

  &:hover {
    background-color: #e5f0fb;
  }
`

const CopyableIcon = styled(UICopyable)`
  && {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    display: none;
    cursor: pointer;

    .sign-svg {
      margin-right: 0;
    }

    ${NestedKey}:hover & {
      display: block;
    }

    ${HeadContainer}:hover & {
      display: block;
    }
  }
`

const getContent = (preContent, open) => {
  if (preContent) {
    return `content: "${open ? '-' : '+'}"`
  } else {
    return `content: ""`
  }
}

const Name = styled.div`
  position: relative;
  padding-left: 30px;
  display: inline-flex;
  font-size: 13px;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  font-weight: ${props => (props.head ? 'bold' : 'normal')};
  color: ${props => (props.head ? '#2aa198' : '#666')};
  line-height: 18px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    display: block;
    ${props => getContent(props.preContent, props.open)};
  }

  ${props =>
    props.match &&
    css`
      color: #0070d9;
    `};

  ${NestedKey}:hover & {
    color: #0070d9;
  }

  ${HeadContainer}:hover & {
    color: #0070d9;
  }
`

const getColorByType = type => {
  switch (type) {
    case 'object':
      return '#999'
    case 'string':
    case 'array':
      return '#d33682'
    case 'number':
      return '#5199dd'
    case 'boolean':
      return '#b58900'
  }
}

const TypeValue = styled.div`
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 18px;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  color: ${props => (props.match ? '#0070d9' : getColorByType(props.type))};

  ${NestedKey}:hover & {
    color: #0070d9;
  }

  ${HeadContainer}:hover & {
    color: #0070d9;
  }
`

const OptionsWrapper = styled.div`
  margin-left: 56px;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  font-size: 12px;
  line-height: 22px;
  color: #999;
`

const getClassNameByPath = ({ className, path, match }) =>
  classNames(className, {
    [getClassVariableByPath(path)]: match,
  })

const NodeFromValue = ({
  name,
  value,
  type,
  open,
  match,
  pathActiveMatchNode,
  toggleOpenByPath,
  onClickCopy,
}) => {
  if (_.isObject(value)) {
    return _.reduce(
      value,
      (nodes, item, key) => {
        if (open || item.open) {
          let node = {}

          if (_.isObject(item.value)) {
            node = (
              <React.Fragment key={key}>
                <NestedKey
                  onClick={event => {
                    toggleOpenByPath(event, item.path)
                  }}
                  match={item.match}
                  className={getClassNameByPath({
                    className: 'pts pbs',
                    path: item.path,
                    match: item.match,
                  })}
                  active={pathActiveMatchNode === item.path}
                >
                  <WithDeprecatedIcon mark={item.mark}>
                    <Name preContent open={item.open} match={item.match}>
                      <div
                        title={item.description}
                        data-toggle="tooltip"
                        data-placement="top"
                        data-container="body"
                      >
                        {key}
                      </div>
                    </Name>
                  </WithDeprecatedIcon>
                </NestedKey>
                <NestedContainer>
                  <NodeFromValue
                    name={key}
                    value={item.value}
                    open={item.open}
                    type={item.type}
                    match={item.match}
                    pathActiveMatchNode={pathActiveMatchNode}
                    onClickCopy={onClickCopy}
                    toggleOpenByPath={toggleOpenByPath}
                  />
                </NestedContainer>
              </React.Fragment>
            )
          } else {
            node = (
              <NestedKey
                key={key}
                spaceBetween
                match={item.match}
                className={getClassNameByPath({
                  className: 'pts pbs',
                  path: item.path,
                  match: item.match,
                })}
                active={pathActiveMatchNode === item.path}
              >
                <WithDeprecatedIcon mark={item.mark}>
                  <div
                    title={item.description}
                    data-toggle="tooltip"
                    data-placement="top"
                    data-container="body"
                  >
                    <Name preContent={false} open={item.open} match={item.match}>
                      {`${key}`}
                    </Name>
                  </div>
                </WithDeprecatedIcon>
                <NodeFromValue
                  name={key}
                  value={item.value}
                  open={item.open}
                  type={item.type}
                  match={item.match}
                  pathActiveMatchNode={pathActiveMatchNode}
                  onClickCopy={onClickCopy}
                  toggleOpenByPath={toggleOpenByPath}
                />
                <CopyableIcon
                  text={item.copyPath}
                  balloonPosition="left"
                  onClick={onClickCopy(item.path)}
                >
                  <UIIcon name="copy" width="20" fill="#0070d9" />
                </CopyableIcon>
              </NestedKey>
            )
          }
          nodes.push(node)
        }

        return nodes
      },
      [],
    )
  } else {
    return (
      <TypeValue type={type} match={match}>
        {`${value}`}
      </TypeValue>
    )
  }
}

export const Variable = observer(
  class Variable extends React.Component {
    handleClickCopy = path => () => {
      this.props.gaTrackCopyVariable(path)
    }

    render() {
      const {
        name,
        value,
        open,
        match,
        type,
        description,
        mark,
        path,
        copyPath,
        options,
        toggleOpenByPath,
        pathActiveMatchNode,
      } = this.props

      return (
        <View data-testid="ac-info-sidebar-bar-variable">
          {_.isObject(value) && value !== EMPTY_OBJECT && value !== EMPTY_ARRAY ? (
            <>
              <HeadContainer
                match={match}
                className={getClassNameByPath({
                  path,
                  match,
                })}
                active={pathActiveMatchNode === path}
                open={open}
              >
                <WithDeprecatedIcon mark={mark}>
                  <Name
                    onClick={event => {
                      toggleOpenByPath(event, path)
                    }}
                    head
                    preContent
                    open={open}
                    match={match}
                  >
                    <div
                      title={description}
                      data-toggle="tooltip"
                      data-placement="top"
                      data-container="body"
                    >
                      <span>{name}</span>
                    </div>
                  </Name>
                </WithDeprecatedIcon>
              </HeadContainer>
              <div>
                <NodeFromValue
                  name={name}
                  value={value}
                  open={open}
                  type={type}
                  match={match}
                  description={value.description}
                  pathActiveMatchNode={pathActiveMatchNode}
                  toggleOpenByPath={toggleOpenByPath}
                  onClickCopy={this.handleClickCopy}
                />
              </div>
            </>
          ) : (
            <HeadContainer
              match={match}
              className={getClassNameByPath({
                path,
                match,
              })}
              active={pathActiveMatchNode === path}
            >
              <Name
                head
                title={description}
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                match={match}
              >
                <span>{name}</span>
              </Name>
              <TypeValue type={type} match={match}>
                {value}
              </TypeValue>
              <CopyableIcon
                text={copyPath}
                balloonPosition="left"
                onClick={this.handleClickCopy(path)}
              >
                <UIIcon name="copy" width="20" fill="#0070d9" />
              </CopyableIcon>
            </HeadContainer>
          )}
          {_.some(options) && (
            <OptionsWrapper>Options: {options.join(', ')}</OptionsWrapper>
          )}
        </View>
      )
    }
  },
)
