/**
 * @prettier
 */
import { types, onSnapshot, applySnapshot } from 'mobx-state-tree'

export const EMPTY_OBJECT = '{ }'
export const EMPTY_ARRAY = '[ ]'

export const getClassVariableByPath = (path = '') =>
  `variable-${path.split('.').join('-')}`

const getCopyPath = path => `{{ ${path} }}`

export const InfoSidebarStore = types
  .model('InfoSidebarStore', {
    data: types.optional(types.frozen(), {}),
    query: '',
    pathNodeVariables: types.optional(types.array(types.string), []),
    haveMatches: false,
    matchNodes: types.optional(types.array(types.string), []),
    activeMatchNode: -1,
    gaCategory: 'Variables',
  })
  .views(self => ({
    get showVariables() {
      return self.query === '' || self.haveMatches
    },
    get frequentlyUsedVariables() {
      const variables = [
        {
          path: 'advocate_incentive',
          key: 'description',
          description: 'Incentive for Advocate',
        },
        {
          path: 'friend_incentive',
          key: 'description',
          description: 'Incentive for Friend',
        },
        {
          path: 'site_setup',
          key: 'url',
          description: 'Site URL',
        },
        {
          path: 'site_setup',
          key: 'name',
          description: 'Site Name',
        },
      ]

      return variables.reduce((acc, variable) => {
        const value = _.get(self.data, `${variable.path}.value.${variable.key}.value`)

        if (value) {
          acc.push({
            ...variable,
            value,
          })
        }

        return acc
      }, [])
    },
    get pathActiveMatchNode() {
      return self.matchNodes.length > 0 ? self.matchNodes[self.activeMatchNode] : false
    },
  }))
  .actions(self => ({
    afterCreate() {
      onSnapshot(self.matchNodes, () => {
        if (_.some(self.matchNodes)) {
          self.initActiveMatchNode()
        }
      })
    },
    onChangeQuery(event) {
      self.query = event.target.value
      self.cleanMatchNodes()
      self.updateDataByQuery()
    },
    updateData(data) {
      self.data = self.normalizeData(data)
    },
    updatePathToNode(pathToNode, key) {
      return pathToNode.slice(0, pathToNode.length - key.length - 1)
    },
    resetQuery() {
      self.query = ''
      self.activeMatchNode = -1
      self.cleanMatchNodes()
      self.updateDataByQuery()
    },
    updateHaveMatches(value) {
      self.haveMatches = value
    },
    updateMatchNodes(value) {
      if (!self.matchNodes.includes(value)) self.matchNodes.push(value)
    },
    cleanMatchNodes() {
      self.matchNodes.replace([])
    },
    openParentNodesByPath(path = '') {
      const data = _.cloneDeep(self.data)
      let pathToNode = path

      path
        .split('.')
        .reverse()
        .forEach((key, index, arr) => {
          if (key !== 'value') {
            _.update(data, pathToNode, value => ({
              ...value,
              open: true,
            }))
          }
          pathToNode = self.updatePathToNode(pathToNode, key)
        })
      self.data = data
    },
    previousActiveMatchNode() {
      self.activeMatchNode =
        self.activeMatchNode !== 0 ? self.activeMatchNode - 1 : self.matchNodes.length - 1
      self.openParentNodesByPath(self.matchNodes[self.activeMatchNode])
      self.scrollToVariable()
    },
    nextActiveMatchNode() {
      self.activeMatchNode =
        self.activeMatchNode + 1 >= self.matchNodes.length ? 0 : self.activeMatchNode + 1
      self.openParentNodesByPath(self.matchNodes[self.activeMatchNode])
      self.scrollToVariable()
    },
    initActiveMatchNode() {
      self.activeMatchNode = 0
      setTimeout(() => {
        self.scrollToVariable()
      }, 200)
    },
    scrollToVariable() {
      if (self.haveMatches) {
        const node = $(
          `.${getClassVariableByPath(self.matchNodes[self.activeMatchNode])}`,
        )[0]

        if (node) {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          })
        } else {
          setTimeout(() => {
            self.scrollToVariable()
          }, 0)
        }
      }
    },
    updateDataByQuery() {
      const data = _.cloneDeep(self.data)
      let haveMatches = false

      self.pathNodeVariables.forEach(pathNode => {
        let pathToNode = pathNode

        pathNode
          .split('.')
          .reverse()
          .forEach((key, index, arr) => {
            if (key !== 'value') {
              _.update(data, pathToNode, value => ({
                ...value,
                open: false,
                match: false,
              }))
            }
            pathToNode = self.updatePathToNode(pathToNode, key)
          })
      })
      self.pathNodeVariables.forEach(pathNode => {
        let pathToNode = pathNode
        let nodeHaveMatch = false

        pathNode
          .split('.')
          .reverse()
          .forEach((key, index, arr) => {
            if (key !== 'value') {
              _.update(data, pathToNode, value => ({
                ...value,
                match: self.matchQuery({ path: value.copyPath, key }),
                open: value.open || nodeHaveMatch,
              }))
              if (self.matchQuery({ path: _.get(data, `${pathToNode}.copyPath`), key })) {
                nodeHaveMatch = true
                haveMatches = true
                self.updateMatchNodes(_.get(data, `${pathToNode}.path`))
              }
            }
            pathToNode = self.updatePathToNode(pathToNode, key)
          })
      })
      self.updateHaveMatches(haveMatches)
      self.data = data
    },
    matchQuery({ path, key }) {
      return (
        self.query !== '' &&
        (_.includes(self.query, '.') ||
        _.includes(self.query, '[') ||
        _.includes(self.query, ']')
          ? _.includes(path, self.query)
          : _.includes(getCopyPath(key), self.query))
      )
    },
    updatePathNodeVariables(path) {
      self.pathNodeVariables.push(path)
    },
    normalizeData(data) {
      const getValue = ({ data, parentPath, depth, path, description, mark }) => {
        const open = depth === 0

        if (_.isObject(data)) {
          if (_.isArray(data)) {
            if (_.some(data)) {
              const arr = _.filter(data, item => !_.isObject(item))

              if (_.some(arr)) {
                self.updatePathNodeVariables(path)

                return {
                  description,
                  mark,
                  path,
                  copyPath: getCopyPath(parentPath),
                  value: arr.join(', '),
                  open,
                  match: false,
                  type: 'array',
                }
              } else {
                return {
                  description,
                  value: _.reduce(
                    data,
                    (variables, obj, index) => {
                      variables[index] = {
                        ...getValue({
                          data: !_.isUndefined(obj?.value) ? obj?.value : obj,
                          description: _.isString(obj?.description)
                            ? obj?.description
                            : null,
                          parentPath: `${parentPath}[${index}]`,
                          depth: depth + 1,
                          path: `${path}.value.${index}`,
                          mark: obj?.mark,
                        }),
                        path: `${path}.value.${index}`,
                        copyPath: getCopyPath(`${parentPath}[${index}]`),
                        open: false,
                        match: false,
                        mark: obj?.mark,
                        type: 'object',
                      }

                      return variables
                    },
                    {},
                  ),
                }
              }
            } else {
              self.updatePathNodeVariables(path)

              return {
                mark,
                description,
                path,
                copyPath: getCopyPath(parentPath),
                value: EMPTY_ARRAY,
                open,
                match: false,
                type: 'object',
              }
            }
          } else {
            if (_.some(data)) {
              return {
                description,
                value: _.reduce(
                  data,
                  (variables, item, key) => {
                    variables[key] = {
                      ...getValue({
                        data: !_.isUndefined(item?.value) ? item?.value : item,
                        description: _.isString(item?.description)
                          ? item?.description
                          : null,
                        parentPath: `${parentPath}.${key}`,
                        depth: depth + 1,
                        path: `${path}.value.${key}`,
                        mark: item?.mark,
                      }),
                      path: `${path}.value.${key}`,
                      copyPath: getCopyPath(`${parentPath}.${key}`),
                      open: false,
                      match: false,
                      mark: item?.mark,
                    }

                    return variables
                  },
                  {},
                ),
              }
            } else {
              self.updatePathNodeVariables(path)

              return {
                mark,
                description,
                path,
                copyPath: getCopyPath(parentPath),
                value: EMPTY_OBJECT,
                open,
                match: false,
                type: 'object',
              }
            }
          }
        }
        self.updatePathNodeVariables(path)

        return {
          mark,
          description,
          path,
          copyPath: getCopyPath(parentPath),
          value: typeof data !== 'string' ? String(data) : `"${data}"`,
          open,
          match: false,
          type: typeof data,
        }
      }

      const variables = {}

      _.map(data, ({ description, value, options, mark }, key) => {
        let depth = 0

        if (description) {
          variables[key] = {
            options,
            ...getValue({
              mark,
              description,
              data: value,
              parentPath: key,
              depth,
              path: key,
            }),
            open: false,
            path: key,
            copyPath: getCopyPath(key),
            match: false,
            type: typeof value,
          }
        }
      })

      return variables
    },
    hideChildVariables(variables) {
      _.map(variables, (variable, key) => {
        variables[key].open = false
        if (_.isObject(variable.value)) {
          self.hideChildVariables(variable.value)
        }
      })
    },
    toggleOpenByPath(event, path) {
      event.stopPropagation()
      const dataUpdate = _.cloneDeep(self.data)

      _.update(dataUpdate, `${path}.open`, open => !open)
      Utils.trackGAEvent(
        self.gaCategory,
        `${_.get(dataUpdate, `${path}.open`) ? 'open' : 'close'} variable`,
        path,
      )
      if (_.isObject(_.get(dataUpdate, `${path}.value`))) {
        self.hideChildVariables(_.get(dataUpdate, `${path}.value`))
      }
      self.data = dataUpdate
    },
    gaTrackCopyVariable(path) {
      Utils.trackGAEvent(self.gaCategory, 'copy to clipboard', path)
    },
    reset() {
      applySnapshot(self, {
        data: {},
        query: '',
        pathNodeVariables: [],
        haveMatches: false,
        matchNodes: [],
        activeMatchNode: -1,
        gaCategory: 'Variables',
      })
    },
  }))
