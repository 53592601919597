/**
 * @prettier
 */
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { UIInputText, UIIcon } from 'talkable-ui-kit'

const ResetIcon = styled(UIIcon)`
  && {
    cursor: pointer;
    margin-right: 0;

    &:hover {
      path {
        fill: #999;
      }
    }
  }
`

const IconSearch = styled(UIIcon)`
  && {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    margin-right: 0;
  }
`

const ScrollToVariable = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  align-items: center;
`

const IconPrevious = styled(UIIcon)`
  && {
    transform: rotate(180deg);
    cursor: pointer;

    &:hover {
      g {
        fill: #999;
      }
    }
  }
`

const IconNext = styled(UIIcon)`
  && {
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      g {
        fill: #999;
      }
    }
  }
`

const Matches = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #ccc;
  color: #333;
`

const InputSearch = styled(UIInputText)`
  && {
    padding: 0 130px 0 30px;

    &::-webkit-input-placeholder {
      opacity: 0.5;
    }

    &:focus {
      border-color: rgba(0, 112, 217, 0.5);
      box-shadow:
        0 1px 4px 0 rgba(0, 112, 217, 0.2),
        inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);

      & + ${IconSearch} {
        g {
          stroke: #0070d9;
        }
      }
    }
  }
`

const WrapperInputSearch = styled.div`
  margin: 0 30px 20px 30px;
  position: relative;
`

export const InputSearchComponent = observer(
  class InputSearchComponent extends React.Component {
    constructor() {
      super()
      this.inputSearchRef = React.createRef()
    }

    componentDidMount() {
      setTimeout(() => {
        this.inputSearchRef.current?.refInput.current.focus()
      }, 0)
    }

    handleKeyDown = event => {
      if (event.keyCode === 13) {
        Utils.trackGAEvent(
          this.props.infoSidebarStore.gaCategory,
          'go to a variable by Enter/Shift + Enter',
        )
        event.shiftKey
          ? this.props.infoSidebarStore.previousActiveMatchNode()
          : this.props.infoSidebarStore.nextActiveMatchNode()
      }
    }

    handleClickIconPrevious = () => {
      Utils.trackGAEvent(
        this.props.infoSidebarStore.gaCategory,
        'go to a variable by icons Previous/Next',
      )
      this.props.infoSidebarStore.previousActiveMatchNode()
    }

    handleClickIconNext = () => {
      Utils.trackGAEvent(
        this.props.infoSidebarStore.gaCategory,
        'go to a variable by icons Previous/Next',
      )
      this.props.infoSidebarStore.nextActiveMatchNode()
    }

    render() {
      const {
        infoSidebarStore: {
          query,
          onChangeQuery,
          resetQuery,
          matchNodes,
          activeMatchNode,
        },
      } = this.props

      return (
        <WrapperInputSearch>
          <InputSearch
            ref={this.inputSearchRef}
            size="medium"
            value={query}
            onChange={_.debounce(onChangeQuery, 200)}
            placeholder="Search variables"
            onKeyDown={this.handleKeyDown}
          />
          <IconSearch name="searchLite" />
          <ScrollToVariable>
            {_.some(matchNodes) && (
              <React.Fragment>
                <Matches>
                  {activeMatchNode + 1}/{matchNodes.length}
                </Matches>
                <IconPrevious
                  name="downArrow"
                  fill="#ccc"
                  onClick={this.handleClickIconPrevious}
                />
                <IconNext
                  name="downArrow"
                  fill="#ccc"
                  onClick={this.handleClickIconNext}
                />
              </React.Fragment>
            )}
            {query && <ResetIcon name="cross" onClick={resetQuery} fill="#ccc" />}
          </ScrollToVariable>
        </WrapperInputSearch>
      )
    }
  },
)
