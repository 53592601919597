/**
 * @prettier
 */
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { UICopyable, UIIcon } from 'talkable-ui-kit'

const Wrapper = styled.div`
  padding: 20px 0;
  background-color: #fafafa;
  border-top: 1px solid #ebebeb;
`

const Title = styled.div`
  margin: 0 30px 20px 30px;
  color: #333;
  font-family: 'gotham-htf-bold', Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
`

const Variable = styled.div`
  position: relative;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: #e5f0fb;
  }
`

const Name = styled.div`
  cursor: pointer;
`

const Path = styled.span`
  font-size: 13px;
  color: #2aa198;
  font-weight: bold;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;

  ${Variable}:hover & {
    color: #0070d9;
  }
`

const Key = styled.span`
  font-size: 13px;
  color: #666;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
  ${Variable}:hover & {
    color: #0070d9;
  }
`

const Value = styled.div`
  max-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #d33682;
  font-family: Monaco, Menlo, Consolas, 'Courier New', monospace;
`

const CopyableIcon = styled(UICopyable)`
  && {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    display: none;
    cursor: pointer;

    .sign-svg {
      margin-right: 0;
    }

    ${Variable}:hover & {
      display: block;
    }
  }
`

export const FrequentlyUsedVariables = observer(
  class FrequentlyUsedVariables extends React.Component {
    handleClickCopy = path => () => {
      this.props.infoSidebarStore.gaTrackCopyVariable(path)
    }

    render() {
      const {
        infoSidebarStore: { frequentlyUsedVariables },
      } = this.props

      return (
        _.some(frequentlyUsedVariables) && (
          <Wrapper data-testid="ac-info-sidebar-variables-block">
            <Title>Frequently used variables</Title>
            {frequentlyUsedVariables.map((variable, key) => (
              <Variable key={key}>
                <Name
                  aria-label={variable.description}
                  data-balloon-pos="right"
                  data-balloon-length="medium"
                >
                  <Path>{variable.path}</Path>
                  <Key>{`.${variable.key}`}</Key>
                </Name>
                <Value>{`${variable.value}`}</Value>
                <CopyableIcon
                  text={`{{ ${variable.path}.${variable.key} }}`}
                  balloonPosition="left"
                  onClick={this.handleClickCopy(`${variable.path}.${variable.key}`)}
                >
                  <UIIcon name="copy" width="20" fill="#0070d9" />
                </CopyableIcon>
              </Variable>
            ))}
          </Wrapper>
        )
      )
    }
  },
)
