/**
 * @prettier
 */
import { observer } from 'mobx-react'
import styled, { css } from 'styled-components'
import { UILoader } from 'talkable-ui-kit'
import { CSSTransition } from 'react-transition-group'

import { Variables } from './variables/variables'
import { InputSearchComponent } from './input_search'
import { FrequentlyUsedVariables } from './variables/frequently_used_variables'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1021;
  background-color: #fff;
  ${({ align }) =>
    align &&
    css`
      ${align}: 0
    `};
  ${({ state, width, align }) => {
    const gap = align === 'right' ? width : `-${width}`

    switch (state) {
      case 'exited':
        return css`
          transform: translateX(${gap});
        `
      case 'entering':
        return css`
          transform: translateX(${gap});
        `
      case 'entered':
        return css`
          transform: translateX(0);
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
          transition: transform 0.25s;
        `
    }
  }};
`

const Container = styled.div`
  position: relative;
  ${props => props.width && `width: ${props.width}`};
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 0 #e6e6e6;
`

const Header = styled.div`
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 1px;
  box-shadow: inset 0 -1px 0 #ebebeb;
`

const Title = styled.div`
  margin-bottom: 20px;
  padding: 0 30px;
  color: #333;
  font-family: 'gotham-htf-bold', Arial, sans-serif;
  font-size: 27px;
  line-height: 32px;
`

const Main = styled.div`
  background-color: #fff;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
`

const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  .sign-svg {
    fill: rgba(0, 0, 0, 0.4);
    stroke: rgba(0, 0, 0, 0.15);
  }

  &:hover .sign-svg {
    fill: rgba(0, 0, 0, 0.8);
    stroke: rgba(0, 0, 0, 0.3);
  }
`

export const InfoSidebarContainer = observer(
  class InfoSidebarContainer extends React.Component {
    static propTypes = {
      align: PT.oneOf(['left', 'right']),
    }

    static defaultProps = {
      width: '480px',
      title: () => null,
      isFetched: true,
      align: 'right',
    }

    handleEscape = e => {
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.props.onClose()
      }
    }

    componentDidMount() {
      document.addEventListener('keydown', this.handleEscape)
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleEscape)
    }

    renderMainContent = () => {
      const { isFetched, content, infoSidebarStore } = this.props

      if (!isFetched) {
        return <UILoader />
      }

      return content ? content() : <Variables infoSidebarStore={infoSidebarStore} />
    }

    render() {
      const { show, width, onClose, isFetched, title, content, infoSidebarStore, align } =
        this.props

      return (
        <CSSTransition in={show} timeout={0} classNames="">
          {state => (
            <Wrapper
              className="js-keep-propagating"
              state={state}
              width={width}
              align={align}
              data-testid="ac-info-sidebar-container"
            >
              <Container className="info-sidebar-bar" width={width}>
                <Header className="info-sidebar-bar-header">
                  <Title>{title()}</Title>
                  <Close className="modal-close" onClick={onClose} />
                  {!content && (
                    <InputSearchComponent infoSidebarStore={infoSidebarStore} />
                  )}
                  {!content && isFetched && (
                    <FrequentlyUsedVariables infoSidebarStore={infoSidebarStore} />
                  )}
                </Header>
                <Main className="info-sidebar-bar-main">{this.renderMainContent()}</Main>
              </Container>
            </Wrapper>
          )}
        </CSSTransition>
      )
    }
  },
)
